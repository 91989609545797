<template>
  <div class="statistics">
    <div class="statistics__row" v-for="(statistic, index) of statistics" :key="index">
      <div class="statistics__row__team">
        <div class="statistics__row__team__data">
          <GamePeriodData
            :title="statistic[0].title"
            :data="statistic[0].data"
            :is-total="statistic[0].isTotal"
            is-mobile
          />
        </div>
        <div class="arrow">
          <div class="arrow__body" />
          <div class="arrow__head right" />
        </div>
      </div>
      <div class="statistics__row__league">
        <GamePeriodData
          :title="statistic[2].title"
          :data="statistic[2].data"
          :is-total="statistic[0].isTotal"
          is-mobile
          is-league
        />
      </div>
      <div class="statistics__row__team">
        <div class="arrow">
          <div class="arrow__head left" />
          <div class="arrow__body" />
        </div>
        <div class="statistics__row__team__data">
          <GamePeriodData
            :title="statistic[1].title"
            :data="statistic[1].data"
            :is-total="statistic[0].isTotal"
            is-mobile
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StatisticsCornerGoalsMobile',
  components: {
    GamePeriodData: () => import('@/components/Sections/GolTipster-v2/General/GamePeriodData'),
  },
  props: {
    dataByPeriod: {
      type: Object,
      default: () => ({
        homeTeam: {
          firstTime: 0,
          secondTime: 0,
        },
        awayTeam: {
          firstTime: 0,
          secondTime: 0,
        },
      }),
    },
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame']),
    statistics() {
      return [
        [
          {
            title: '1ros. Tiempos',
            data: this.dataByPeriod.homeTeam.firstTime,
          },
          {
            title: '1ros. Tiempos',
            data: this.dataByPeriod.awayTeam.firstTime,
          },
          {
            title: 'total 1t',
            data: this.dataByPeriod.homeTeam.firstTime + this.dataByPeriod.awayTeam.firstTime,
          },
        ],
        [
          {
            title: '2dos. Tiempos',
            data: this.dataByPeriod.homeTeam.secondTime,
          },
          {
            title: '2dos. Tiempos',
            data: this.dataByPeriod.awayTeam.secondTime,
          },
          {
            title: 'total 2t',
            data: this.dataByPeriod.homeTeam.secondTime + this.dataByPeriod.awayTeam.secondTime,
          },
        ],
        [
          {
            title: 'total',
            data: this.dataByPeriod.homeTeam.firstTime + this.dataByPeriod.homeTeam.secondTime,
            isTotal: true,
          },
          {
            title: 'total',
            data: this.dataByPeriod.awayTeam.firstTime + this.dataByPeriod.awayTeam.secondTime,
            isTotal: true,
          },
          {
            title: 'total',
            data:
              this.dataByPeriod.homeTeam.firstTime +
              this.dataByPeriod.awayTeam.firstTime +
              this.dataByPeriod.homeTeam.secondTime +
              this.dataByPeriod.awayTeam.secondTime,
            isTotal: true,
          },
        ],
      ];
    },
  },
};
</script>

<style scoped lang="scss">
$arrowHeadHeight: 4px;

.statistics {
  width: 100%;

  &__row {
    display: grid;
    grid-template-columns: 1fr 6.625rem 1fr;
    margin: 0.5rem 0;

    &__team {
      display: flex;
      align-items: center;

      &__data {
        width: 6.625rem;
      }
    }

    @media screen and (max-width: 360px) {
      grid-template-columns: 1fr 5.8rem 1fr;

      &__team__data {
        width: 5.8rem;
      }
    }

    @media screen and (max-width: 330px) {
      grid-template-columns: 1fr 4.8rem 1fr;

      &__team__data {
        width: 4.8rem;
      }
    }
  }
}

.arrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;

  &__head {
    width: 0;
    height: 0;
    border-top: $arrowHeadHeight solid transparent;
    border-bottom: $arrowHeadHeight solid transparent;

    &.left {
      border-right: 6px solid black;
    }

    &.right {
      border-left: 6px solid black;
    }
  }

  &__body {
    margin: 0;
    border-top: solid 2px black;
    width: 100%;
  }
}
</style>
